import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>Amb psicòleg i comandament dels Mossos d'Esquadra.</p>

                <p>
                    Vols posar-te a prova amb un simulacre d'entrevista amb un psicòleg i un
                    comandament dels Mossos d'Esquadra?
                </p>

                <p>
                    <strong>Recreació del mateix escenari que el dia de la prova oficial.</strong>
                </p>

                <p>Aquesta preparació és encara més personalitzada i real.</p>

                <p>
                    A <strong>iOpos</strong> tenim la nostra pròpia metodologia de formació i el
                    nostre percentatge d'aprovats és altíssim.
                </p>

                <p>
                    Treballarem tots els aspectes clau que necessitaràs per afrontar la teva
                    entrevista amb èxit.
                </p>

                <p>
                    <strong>La metodologia és del tot personalitzada</strong> i es posarà èmfasi en
                    les preguntes-respostes més importants d'acord amb cada alumne.
                </p>

                <ul>
                    <li>
                        <strong>Cada alumne és analitzat individualment.</strong>
                    </li>
                    <li>
                        T'ajudarem a treballar la teva entrevista personal des d'un punt de vista
                        honest i natural.
                    </li>
                </ul>

                <p>
                    <strong>45 minuts de simulacre i 15 minuts de feedback final.</strong>
                </p>

                <p>
                    <strong>Escull dia i hora...</strong> T'esperem!
                </p>

                <div className="mt-20">
                    <h1 className="font-bold font-montserrat text-xl text-cpurple">
                        Entrevistes disponibles per grup de formadors i ubucació
                    </h1>
                    <div id="disponibles" />
                    <div className="grid grid-cols-1 gap-10 mt-10">
                        <div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formadors Jordi (Psicòleg) i Rubén (Comandament de Mossos) Carrer de
                                les Travesseres, 43, 08401 Granollers, Barcelona (COWORKING)
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/d/crhn-mwb-bqq/simulacre-d-entrevista-premium-1-00-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                // className="-mt-10"
                                url="https://calendly.com/d/crhn-mwb-bqq/simulacre-d-entrevista-premium-1-00-h?hide_gdpr_banner=1"
                            />
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/oY7K6UQyXw8"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Posa't a prova amb un simulacre real d'entrevista amb psicòleg i comandament dels Mossos!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { InlineWidget } from "react-calendly";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>            
            <div className="container lg:px-0 py-20">
                <Title>Simulacre d'Entrevista Premium 2024</Title>
                <Text>
                    ¿Vols posar-te a prova amb un simulacre d'entrevista amb un comandament de la
                    Guàrdia Urbana de Barcelona i un psicòleg especialista?
                    <br />
                    <br />
                    T'oferim un simulacre PREMIUM encara més personalitzat i ajustat a la prova
                    oficial.
                    <br />
                    <br />
                    En el simulacre, comptaràs amb la presència d'un comandament de la Guàrdia
                    Urbana de Barcelona, i un psicòleg, exmembre de l'ISPC.
                    <br />
                    <br />
                    La metodologia és completament personalitzada i es posarà èmfasi en les
                    preguntes-respostes més importants per a cada alumne.
                    <br />
                    <br />
                    En definitiva, t'ajudarem a treballar la teva entrevista personal des d'un punt
                    de vista honest i natural.
                    <br />
                    <br />
                    La preparació consisteix en una hora de vis-a-vis amb un preparador
                    especialitzat que t'analitzarà i guiarà de la millor manera possible.
                    <br />
                    <br />
                    45 minuts de simulacre, amb role play i 15 minuts de feedback final.
                    <br />
                    <br />
                    En acabar l'entrevista, et proporcionarem una avaluació orientativa de les
                    competències.
                    <br />
                    <br />
                    Escull dia i hora, t'esperem!
                </Text>

                <div id="disponibles"></div>

                <div className="mt-10 border-t border-b border-gray-300 pt-5 pb-10">
                    <h1 className="font-bold uppercase font-montserrat text-xl text-cpurple">
                        Entrevistes disponible per Formador i Ubicació
                    </h1>

                    <div className="grid grid-cols-1 gap-10 mt-10">
                        <div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Jordi Psicòleg i Maite - C/ Martí Codolar 18 - Hospitalet de
                                Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat">
                                Si no ves correctamente el calendario haz clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/d/ck2f-gp8-my2/simulacre-d-entrevista-premium-1-00-h-b"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/d/ck2f-gp8-my2/simulacre-d-entrevista-premium-1-00-h-b?hide_gdpr_banner=1"
                            />
                        </div>
                    </div>

                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/S6UpxRZZXXg"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
*/
